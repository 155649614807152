
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IItem, IItemUpdate, ILabel, ILabelCreate } from "@/interfaces";
import TextHighlight from "vue-text-highlight";

import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateLabel } from "@/store/model/actions";
import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";
import { readDatasetByStoreId } from "@/store/dataset/getters";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";
import TextEditor from "@/components/TextEditor.vue";

@Component({
  components: {
    TextHighlight,
    TextEditor,
  },
})
export default class ItemPreviewContext extends Vue {
  // internal properties

  @Prop({ default: false }) public item!: IItem;
  @Prop({ default: false }) public loadingexternal?: boolean;
  @Prop({ default: false }) public targetItems?: IItemUpdate[];
  @Prop({ default: true }) public flat!: boolean;
  @Prop({ default: false }) public change!: boolean;
  @Prop({ default: false }) public single!: boolean;
  @Prop({ default: 0 }) public dataset_id!: number;
  @Prop({}) public highlights!: string[];
  @Prop({}) public recommendation: string;
  public hide = false;
  public addButtonColor = "teal";
  public addLabel = false;
  public modelChoiceDialog: boolean = false;
  public searchTerm: string = "";
  /**
   * upload event...
   */
  public id: number = 1;
  public labelDescription: string = "";
  public labelName: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public e6: number = 0;
  public uploadError: any = null;
  public color: string = "green";
  public connectedModels: any = [];
  public menu: boolean = false;
  public slicedConversation: any = [];
  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];
  public userChanged: boolean = false;

  // get dataset() {
  //   console.log("Getting dataset id", this.item.store_id);
  //   return readDatasetByStoreId(this.$store)(this.item.store_id);
  // }

  public setNewText(newText: string, itemId: string) {
    const index = this.item.context?.findIndex((obj) => obj._id === this.item.id);
    if (index !== undefined && index !== -1 && !!this.item.context) {
      this.item.context[index].plain_text = newText;
    }
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  public async mounted() {
    this.sliceConversation();
    // await this.getDatasetConnectedModels();
  }

  get token() {
    return readToken(this.$store);
  }

  public sliceConversation() {
    const index = this.item.context?.findIndex((obj) => obj._id === this.item.id);
    if (
      index !== undefined &&
      index !== -1 &&
      !!this.firstLabelContainer &&
      (!!this.firstLabelContainer.previous_utterances ||
        !!this.firstLabelContainer.following_utterances) &&
      !!this.item.context
    ) {
      var start = 0;
      var end = this.item.context.length;
      if (this.firstLabelContainer.previous_utterances !== null) {
        start = Math.max(0, index - this.firstLabelContainer.previous_utterances);
      }
      if (this.firstLabelContainer.following_utterances !== null) {
        end = Math.min(
          this.item.context.length,
          index + 1 + this.firstLabelContainer.following_utterances,
        );
      }
      this.slicedConversation = this.item.context.slice(start, end);
    } else {
      this.slicedConversation = this.item.context;
    }
  }

  public async getConversationIdAndRoute(itemId: string) {
    const index = this.item.context?.findIndex((obj) => obj._id === this.item.id);
    if (index !== undefined && index !== -1 && !!this.item.context) {
      window.open(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.item.dataset_id}/dashboard/browse?conversation_id=${this.item.conversation_id}&start=${this.item.context[index].start}`,
        "_blank",
      );
    } else {
      window.open(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.item.dataset_id}/dashboard/browse?conversation_id=${this.item.conversation_id}&start=0`,
        "_blank",
      );
    }
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }
  public sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }
  // Levenshtein distance function
  public levenshtein(a: string, b: string): number {
    const matrix = Array.from({ length: a.length + 1 }, () => new Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }
    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost,
        );
      }
    }

    return matrix[a.length][b.length];
  }

  // N-gram public
  public ngrams(s: string, n: number): Set<string> {
    const result = new Set<string>();

    for (let i = 0; i <= s.length - n; i++) {
      result.add(s.slice(i, i + n));
    }

    return result;
  }

  // Main search public
  public searchCategories(query: string, categories: ILabel[]): ILabel[] {
    const ngramSize = 3;
    const queryNgrams = this.ngrams(query.toLowerCase(), ngramSize);

    const scoredCategories = categories.map((category) => {
      const categoryNgrams = this.ngrams(category.name.toLowerCase(), ngramSize);
      const intersectionSize = [...queryNgrams].filter((x) => categoryNgrams.has(x)).length;
      const jaccardIndex =
        intersectionSize / (queryNgrams.size + categoryNgrams.size - intersectionSize);
      const levDist = this.levenshtein(query.toLowerCase(), category.name.toLowerCase());
      const score = jaccardIndex * (1 / (1 + levDist));
      return { ...category, score };
    });

    return scoredCategories.sort((a, b) => b.score - a.score);
  }
  get sortedLabels() {
    if (this.labels) {
      let sorted = this.labels!.map((o) => ({ ...o }));
      sorted = sorted.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
      if (this.item.hasOwnProperty("predictions") && this.item.predictions!.length) {
        const predictions = this.item!.predictions!;

        if (sorted.length !== predictions.length) {
          const predictionIds: number[] = [];
          predictions.forEach((element) => {
            predictionIds.push(element.label);
          });
          sorted.forEach((label) => {
            if (!predictionIds.includes(label.id)) {
              predictions.unshift({ label: label.id, prediction: 0 });
            }
          });
        }
        if (predictions.length && this.recommendation !== "cluster") {
          sorted.sort((a, b) => {
            return (
              predictions!.filter((targetItem) => targetItem.label === b.id)[0].prediction -
              predictions!.filter((targetItem) => targetItem.label === a.id)[0].prediction
            );
          });
        }
      }
      if (sorted.length === 0) {
        sorted = this.labels!.map((o) => ({ ...o }));
        sorted = this.searchCategories(this.searchTerm, sorted);
      }
      return sorted;
    }
    return this.labels;
  }
  public changeColor(color) {}

  public getImageUrl(text) {
    if (text.lastIndexOf("[]()**") > -1) {
      return text.substring(text.lastIndexOf("[]()**") + 6, text.length);
    } else {
      return "";
    }
  }

  public removeImageUrl(text) {
    if (text.lastIndexOf("[]()**") > -1) {
      return text.substring(0, text.lastIndexOf("[]()**"));
    } else {
      return text;
    }
  }

  get colorList() {
    if (this.remainingColors.length > 0) {
      this.color = this.remainingColors[0];
      return this.remainingColors;
    } else {
      this.color = this.colors[Math.floor(Math.random() * this.color.length)];
      return this.colors;
    }
  }

  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter((label) => label.color === c);
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }

    return remainingColors;
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return this.labels!.filter((label) => label.name === this.labelName).length > 0;
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelDescriptionErrors(): string[] {
    if (this.labelDescription.length > 3000) {
      return ["Label description is too long"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (
      this.labelName.length < 1 ||
      this.labelName.length > 40 ||
      this.labelDescription.length > 3000
    ) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }
  public async createLabelAPI() {
    this.addLabel = false;

    if (this.activeLabelExample.length) {
      this.labelExamples.push(this.activeLabelExample);
    }
    this.activeLabelExample = "";
    const newLabel: ILabelCreate = {
      name: this.labelName,
      description: this.labelDescription,
      color: this.color,
      examples: this.labelExamples,
    };
    await dispatchCreateLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      label: newLabel,
    })
      .then((r) => {
        setTimeout(() => {
          const ids: number[] = this.labels.map((l) => {
            return l.id;
          });
          const going = Math.max(...ids);
          this.labelDescription = "";
          this.labelName = "";
          this.uploadError = null;
          this.labelExamples = [];
          if (this.labels.length === 2 && this.firstLabelContainer!.type === "single") {
            this.changed(0, 0, 0);
          }
          // this.$router.push({
          //   path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/
          // ${this.$router.currentRoute.params.id}/label/labels/${going}/example`,
          // });
        }, 200);
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError);
        this.uploadError = uploadError.response;
      });
  }

  public labelById(labelId) {
    return this.labels.filter((label) => label.id === labelId)[0];
  }

  public async getModels() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }

  // We decided to hard-code the special column names but we do it via a mapper so that we can easily change it later if we wanna go with dynamic columns in the case of e.g. a zendesk dataset
  public specialColumnMapper = {
    speaker: "speaker",
    filename: "filename",
  };

  public getSpeakerColor(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      if (item._id === this.item.id) {
        return "#90caf9";
      }
      return "#CCE4F8";
    } else if (item[this.specialColumnMapper.speaker] === "caller") {
      if (item._id === this.item.id) {
        return "#80CBC4";
      }
      return "#C0E2DF";
    } else {
      if (item._id === this.item.id) {
        return "#9b58ed";
      }
      return "#D1C4E9";
    }
  }

  public getSpeakerIcon(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      return "support_agent";
    } else if (item[this.specialColumnMapper.speaker] === "caller") {
      return "face";
    } else {
      return "smart_toy";
    }
  }

  public shouldBeOnRight(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      return true;
    } else {
      return false;
    }
  }

  // public filteredLabels(item: any) {
  //   // Filter out the labels that are not present in unpicked connected models
  //   return item.user_labels_and_predictions.filter((labelPrediction: any) => {
  //     // Check if the label_container is present in any unpicked connected model
  //     const isPresentInUnpickedModel = this.connectedModels.some((model: any) => {
  //       return !model.picked && model.label_containers[0].id === labelPrediction.label_container;
  //     });
  //     // Return true if the label_container is NOT present in any unpicked connected model
  //     return !isPresentInUnpickedModel;
  //   });
  // }

  // public async getDatasetConnectedModels() {
  //   await api
  //     .getDatasetConnectedModels(
  //       this.token,
  //       parseInt(this.$router.currentRoute.params.workspaceid, 10),
  //       parseInt(this.$router.currentRoute.params.id, 10),
  //     )
  //     .then((r) => {
  //       this.connectedModels = r.data.map((model) => ({
  //         ...model,
  //         picked: true, // Default value for 'picked'
  //         expanded: false, // Default value for 'expanded'
  //       }));
  //     })
  //     .catch((error) => {
  //       console.log("error when getting chosen dataset", error);
  //     });
  // }

  public removeLabelExample(toRemove) {
    let tmpExamples = this.labelExamples.slice();
    tmpExamples = tmpExamples.filter((example) => example !== toRemove);
    this.labelExamples = tmpExamples;
  }

  public addLabelExample() {
    if (this.activeLabelExample === "") {
      this.createLabelAPI();
    } else if (!this.labelExamples.includes(this.activeLabelExample)) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }

  get maxConfidence() {
    if (this.item!.hasOwnProperty("predictions") && this.item!.predictions.length !== 0) {
      const ids: number[] = this.item!.predictions.map((l) => {
        return l.prediction;
      });
      const going = Math.max(...ids);
      return going;
    }
    return 1;
  }
  get thresholdConfidence() {
    return 1 / this.labels!.length + 0.15;
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get glowOrNot() {
    if (
      this.maxConfidence <= this.thresholdConfidence &&
      this.change &&
      this.recommendation !== "validation" &&
      this.recommendation !== "cluster"
    ) {
      return "glowbox";
    }
    return "";
  }
  get getTarget() {
    if (this.hide) {
      return {
        color: "grey",
        name: "ignore",
      };
    }
    if (this.recommendation === "validation" && !this.single && !this.userChanged) {
      return {
        color: "grey",
        name: "Pick a label",
      };
    }
    if (this.recommendation === "cluster" && !this.userChanged) {
      return {
        color: "grey",
        name: "Pick a label",
      };
    }
    if (this.recommendation === "plain_text_search" && !this.userChanged) {
      return {
        color: "grey",
        name: "Pick a label",
      };
    }
    if (this.recommendation === "synthetic" && !this.userChanged) {
      return {
        color: "grey",
        name: "Pick a label",
      };
    }

    const filteredItems = this.targetItems!.filter((targetItem) => targetItem.id === this.item.id);
    if (filteredItems.length > 0) {
      return this.getLabelById(filteredItems[0].user_labels[0].label);
    }
    return 0;
  }

  get hideThis() {
    if (this.hide) {
      return "background-color: rgb(255 198 198);";
    }
    return "";
  }

  public getLabelById(id) {
    const filteredItems = this.labels!.filter((label) => label.id === id);
    if (filteredItems.length > 0) {
      return filteredItems[0];
    }
    return 0;
  }

  @Emit()
  public changed(labelid, itemid, storeid) {
    if (labelid !== 0 && itemid !== 0 && storeid !== 0) {
      this.userChanged = true;
    }
    if (labelid !== -2) {
      this.hide = false;
    }
  }
}
